import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import {AppConstant} from "../app.constant";

const evalApi: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_API_URL || "",
    headers: {
        "Content-Type": "application/json",
    },
});

interface RetryConfig extends AxiosRequestConfig {
    retry: number;
    retryDelay: number;
}

const globalConfig: RetryConfig = {
    retry: 3,
    retryDelay: 1000,
};

evalApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const { config } = error;

        if (!config || !config.retry) {
            return Promise.reject(error);
        }
        config.retry -= 1
        const delayRetryRequest = new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log("retry the request", config.url);
                resolve();
            }, config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => evalApi(config));
    }
);

export {evalApi,globalConfig}

